<template>
  <div id="ContactUs">
    <div class="banner container-fuild text-center">联系我们</div>
    <div class="container">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <img src="../assets/img/联系我们.jpg" alt="联系我们" />
      </div>
      <div class="mapdiv">
        <div id="map" class="col-xs-12 col-sm-12 col-md-12">
          <baidu-map class="BDmap" :center="center" :zoom="15" @ready="handler">
            <!--缩放-->
            <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
            <!--定位-->
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :showAddressBar="true"
              :autoLocation="true"
            ></bm-geolocation>
            <!--点-->
            <bm-marker
              :position="center"
              :dragging="dragging"
              animation="BMAP_ANIMATION_DROP"
            ></bm-marker>
          </baidu-map>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WOW from "wowjs";
import info from "../../CorpInfo/info.js";
export default {
  name: "Contact",
  data() {
    return {
      show: true,
      dragging: true,
      info: info,
      center: {
        lng: info.BDMapInfo.longitude,
        lat: info.BDMapInfo.latitude,
      },
    };
  },
  mounted() {
    var wow = new WOW();
    wow.init();
  },
  methods: {
      handler() {
        // 鼠标缩放
        //this.map.enableScrollWheelZoom(true);
      },
  },
};
</script>
<style scoped>
.banner {
  color: #fff;
  font-size: 30px;
  height: 150px;
  line-height: 150px;
  background-image: url("../assets/img/banner_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
}
.ContactUs-container {
  padding: 100px 0;
  transition: all ease 0.5s;
  box-sizing: border-box;
  font-size: 25px;
  font-family: "Microsoft YaHei";
}

#ContactUs img {
  margin-top:30px;
  width: 100%;
  margin-bottom:30px;
}
.BDmap {

  width: 100%;
  height: 400px;
  margin-bottom: 100px;
  padding: 0;
}
.row {
  margin-right: 0;
  margin-left: 0;
  padding: 0;
}
@media screen and (max-width: 997px) {
  .ContactUs-container {
    padding: 0;
  }

.zy-required:before {
  content: "*";
  color: red;
}
.BDmap {
  margin-top: 30px;
  width: 100%;
  height: 200px;
  margin-bottom: 50px;
}
.mapdiv{
  margin-right: 20px;
}
.map {
  margin: auto;
}
}
</style>


<template>
  <div id="Solution5">
    <!--解决方案-->
    <div class="container">
      <div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div>
              <a @click="back()">&lt;-返回</a>
            </div>
            <div class="text">
              <h1>{{ name }}</h1>
              <p>{{ text1 }}</p>
              <img :src="img1" />
              <br />
              <h2>智能虫情测报灯</h2>
              <p>{{ text2 }}</p>
              <br />
              <h2>实时预警监控系统</h2>
              <p>{{ text3 }}</p>
              <br />
              <h2>移动终端</h2>
              <p>{{ text4 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "农作物病虫害检测系统",
      text1:
        "\t" +
        "农作物病虫害监测系统，采用智能虫情测报系统、智能病害监测系统，实时预警监控系统、自动性诱检测仪、农业环境监控系统。",
      text2: "\t" + "可无公害杀虫，改变了传统的测报方式，简化了工作流程。",
      text3:
        "\t" +
        "通过曲线图，表格等形式展示发展情况，当数据超出预设情况后，进行报警。",
      text4: "\t" + "可以通过手机或电脑等设备对数据进行监测，远程操控。",
      img1: require("@/assets/Solution/Solution5/img1.jpg"),
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style >
#Solution5 h1 {
  white-space: pre;
  text-align: center;
  margin-bottom: 20px;
}
#Solution5 a {
  font-size: 20px;
}
#Solution5 p {
  white-space: pre-wrap;
}
#Solution5 h2 {
  white-space: pre-wrap;
  color: rgb(33, 160, 132);
}
#Solution5 .text {
  white-space: pre;
  font-size: 20px;
}
#Solution5 img {
  width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-bottom: 20px;

}
</style>
<template>
  <div id="case_1">
    <div>
      <h3>{{ name }}</h3>
    </div >
       <br/>
    <div v-for="(intm, index) in imgpath" :key="index">
      <img :src="intm.path" alt="案例" >   
      <h1></h1>
    </div>
  </div>
</template>
<script>
export default {
  name: "case4",
  data() {
    return {
      name: "海南大学植物保护学院",
      imgpath: [
            { path: require('@/assets/case4/photo1.jpg'), },
            { path: require("@/assets/case4/photo2.jpg"), },
            { path: require("@/assets/case4/photo3.jpg"), },
      ],
    };
  },
};
</script>
<style scoped>
#case_1 {
  text-align: center;
}
#case_1 img {
  width: 100%;
    margin-top: 10px;
}
</style>


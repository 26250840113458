<template>
  <div id="Solution2">
    <!--解决方案-->
    <div class="container">
      <div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div>
              <a @click="back()">&lt;-返回</a>
            </div>
            <div class="text">
              <h1>{{ name }}</h1>
              <p>{{ text1 }}</p>
              <img :src="img1" />
              <h2>优势</h2>
              <p>{{ text2 }}</p>
              <img :src="img2" />
              <br/>
              <img :src="img3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "数字渔业",
      text1:
        "\t" +
        "水产养殖池塘老化，水污染，病菌感染，效益下降等问题逐渐展现出来，采用传统人工靠经验进行水产养殖的方法很容易在养殖过程中造成调控不及时，反馈较慢，出现大面积死亡等现象，造成巨大经济损失。传统的方法已经不能满足现代水产养殖精准化和智能化的需求。数字渔业指在生产、分配、流通、消费等环节及信息的数字化、网络化、系统化，渔业系统内部及外部的信息集成。" +
        "\n\t" +
        "我国将现有的各种渔业信息数字化的基础上，利用“3S”技术，将渔业生产基础管理。 目前，“3S”技术在渔业领域得到广泛的发展，发挥了及其重要的作用。",
      text2:
        "\t" +
        "1、渔业信息化已经成了渔业界的共识。" +
        "\n\t" +
        "2、我国渔业科技研发部门开展了一系列的RS技术/GPS技术的研发，这些技术的开展，将会促进渔业的长期发展。" +
        "\n\t" +
        "3、渔业实用数据库和信息系统建设已经形成了一定的基础。" +
        "\n\t" +
        "4、近些年来，国家也越来越越重视渔业的发展。",
      img1: require("@/assets/Solution/Solution2/img1.jpg"),
      img2: require("@/assets/Solution/Solution2/img2.jpg"),
      img3: require("@/assets/Solution/Solution2/img3.jpg"),

    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style >
#Solution2 h1 {
  white-space: pre;
  text-align: center;
  margin-bottom: 20px;
}
#Solution2 a {
  font-size: 20px;
}
#Solution2 p {
  white-space: pre-wrap;
}
#Solution2 h2 {
  white-space: pre-wrap;
  color: rgb(33, 160, 132);
}
#Solution2 .text {
  white-space: pre;
  font-size: 20px;
}
#Solution2 img {
  width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-bottom: 20px;
}
</style>
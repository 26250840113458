<template>
  <div id="footer">
    <div class="logo">
      <img src="@/assets/img/logo_white.png" alt="北京云智轩科技" />
      <br />
      <span>{{ info.name }}</span>
      <br />
      <br />
    </div>
    <div>
      <span>地址：{{ info.address }} </span>
      <span>Tel：{{ info.tel }} </span>
      <span>Phone：{{ info.phone }} </span>
      <br />
      <span>邮箱：{{ info.email }} </span>
      <span>微信号:{{ info.wechat }} </span>
    </div>
      <br> <br>

    <div  class="RecordIcp">
       <a href="https://beian.miit.gov.cn/" target="_blank" class="RecordIcp">{{info.keepOnRecordIcp}}</a>
       <br/>
       <span>{{ info.name }}  </span>
    </div>

  </div>
</template>

<script>
import CorpInfo from "../../CorpInfo/info.js";
export default {
  name: "Footer",
  data() {
    return {
      info: CorpInfo,
    };
  },
};
</script>


<style scoped>
#footer {
  height: auto;
  background: #474747;
  text-align: center;
  color: #d3d3d3;
  overflow: hidden;
  margin-top: 25px;
}
#footer .logo img {
  height: 50px;
  margin-top: 25px;
}
#footer .logo span {
  font-size: 25px;
  margin-bottom: 20px
}
#footer .RecordIcp{
    margin-bottom:20px;
}
</style>
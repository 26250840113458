<template>
  <div id="CompanyIntroduction">
    <div class="banner container-fuild text-center">关于我们</div>
    <div class="container">
      <div class="row CompanyIntroduction-container">
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <h3>北京云智轩科技有限公司</h3>
          <br/>
          <p class=".text-justify">
            &emsp;&emsp;北京云智轩是一家专注于数字农业应用的研发型企业。公司综合运用物联网、云计算、人工智能等现代信息技术，专业为政府部门、涉农企业、科研院所、种养殖户等提供基于物联网技术的系统集成、软件开发、智能应用和信息服务。
          </p>
          <p class=".text-justify">
            &emsp;&emsp;公司“农业物联网+农业智能装备”双核驱动，精准农业、智能温室、智慧畜禽、鱼菜共生、水肥一体、质量追溯多点支撑，实现了跨越式发展。是农业物联网标准实验室、阿里巴巴IOT合作伙伴联盟理事、成员单位。
          </p>
          <p class=".text-justify">
            &emsp;&emsp;主营产品：农业智能装备、温室、水产、水肥一体化系统、农产品质量追溯系统、农业气象
            /墒情监测系统以及相关的物联网智能硬件。
          </p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
          <img src="../assets/img/公司说明.jpg" alt="说明" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  name: "CompanyIntroduction",
  data() {
    return {};
  },
  mounted() {
    var wow = new WOW();
    wow.init();
  },
};
</script>
<style scoped>
.banner {
  color: #fff;
  font-size: 30px;
  height: 150px;
  line-height: 150px;
  background-image: url("../assets/img/banner1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
}
.row {
  margin-right: 0;
  margin-left: 0;
}
.CompanyIntroduction-container {
  padding: 100px 0;
  color: #808080;
  transition: all ease 0.5s;
}
.CompanyIntroduction-container img {
  margin-top: 40px;
  width: 100%;
}
.CompanyIntroduction-container > div > p {
  font-size: 16px;
  line-height: 2.5rem;
}
.CompanyIntroduction-container > div > h3 {
  text-align: center;
}
@media screen and (max-width: 997px) {
  .CompanyIntroduction-container {
    padding: 10px 0;
    color: #808080;
  }
}
</style>


import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Soft from '../views/Soft.vue'
import Hard from '../views/Hard.vue'
import Solution from '../views/Solution.vue'
import SolutionHome from '../views/Solution/SolutionHome.vue'
import Case from '../views/Case.vue'
import News from '../views/News.vue'
import Introduce from '../views/Introduce.vue'
import Recruit from '../views/Recruit.vue'
import Contact from '../views/Contact1.vue'

import case1 from '@/views/case/case1.vue'
import case2 from '@/views/case/case2.vue'
import case3 from '@/views/case/case3.vue'
import case4 from '@/views/case/case4.vue'
import case5 from '@/views/case/case5.vue'
import case6 from '@/views/case/case6.vue'
import case7 from '@/views/case/case7.vue'
import case8 from '@/views/case/case8.vue'
import case9 from '@/views/case/case9.vue'

import Solution2 from '../views/Solution/Solution2.vue'
import Solution1 from '../views/Solution/Solution1.vue'
import Solution3 from '../views/Solution/Solution3.vue'
import Solution4 from '../views/Solution/Solution4.vue'
import Solution5 from '../views/Solution/Solution5.vue'
import CorpInfo from "../../CorpInfo/info.js";
Vue.use(VueRouter)

const name = CorpInfo.name

const routes = [{
        //根目录重定向
        path: '/',
        redirect: '/home'

    },
    {
        //首页
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            title: '首页 - ' + name
        }
    },
    {
        //软件产品
        path: '/Soft',
        name: 'Soft',
        component: Soft,
        meta: {
            title: '软件产品 - ' + name
        }
    },
    {
        //硬件产品
        path: '/Hard',
        name: 'Hard',
        component: Hard,
        meta: {
            title: '硬件产品 - ' + name
        },
    }, {
        //解决方案
        path: '/Solution/',
        name: 'Solution',
        component: Solution,
        meta: {
            title: '解决方案 - ' + name
        },
        children: [{
                path: '/Solution',
                redirect: '/Solution/SolutionHome'
            },
            {
                path: '/Solution/SolutionHome',
                name: 'SolutionHome',
                component: SolutionHome,
                meta: {
                    title: '解决方案 - ' + name
                },
            },
            {
                path: '/Solution/Solution1',
                name: 'Solution1',
                component: Solution1,
                meta: {
                    title: '解决方案 - ' + name
                },
            }, {
                path: '/Solution/Solution2',
                name: 'Solution2',
                component: Solution2,
                meta: {
                    title: '解决方案 - ' + name
                },
            },
            {
                path: '/Solution/Solution3',
                name: 'Solution3',
                component: Solution3,
                meta: {
                    title: '解决方案 - ' + name
                },
            },
            {
                path: '/Solution/Solution4',
                name: 'Solution4',
                component: Solution4,
                meta: {
                    title: '解决方案 - ' + name
                },
            },
            {
                path: '/Solution/Solution5',
                name: 'Solution5',
                component: Solution5,
                meta: {
                    title: '解决方案 - ' + name
                },
            }
        ]

    }, {
        //成功案例
        path: '/Case',
        name: 'Case',
        component: Case,
        meta: {
            title: '成功案例 - ' + name
        },
        children: [{
                path: '/case',
                redirect: '/Case/case1'
            }, {
                path: '/Case/case1',
                name: 'case1',
                component: case1,
                meta: {
                    title: '成功案例 - ' + name
                },
            }, {
                path: '/Case/case2',
                name: 'case2',
                component: case2,
                meta: {
                    title: '成功案例 - ' + name
                },
            }, {
                path: '/Case/case3',
                name: 'case3',
                component: case3,
                meta: {
                    title: '成功案例 - ' + name
                },
            },
            {
                path: '/Case/case4',
                name: 'case4',
                component: case4,
                meta: {
                    title: '成功案例 - ' + name
                },
            }, {
                path: '/Case/case5',
                name: 'case5',
                component: case5,
                meta: {
                    title: '成功案例 - ' + name
                },
            }, {
                path: '/Case/case6',
                name: 'case6',
                component: case6,
                meta: {
                    title: '成功案例 - ' + name
                },
            }, {
                path: '/Case/case7',
                name: 'case7',
                component: case7,
                meta: {
                    title: '成功案例 - ' + name
                },
            }, {
                path: '/Case/case8',
                name: 'case8',
                component: case8,
                meta: {
                    title: '成功案例 - ' + name
                },
            },
            {
                path: '/Case/case9',
                name: 'case9',
                component: case9,
                meta: {
                    title: '成功案例 - ' + name
                },
            },

        ]
    },
    {
        //新闻动态
        path: '/News',
        name: 'News',
        component: News,
        meta: {
            title: '新闻动态 - ' + name
        }
    },
    {
        //公司介绍
        path: '/Introduce',
        name: 'Introduce',
        component: Introduce,
        meta: {
            title: '公司介绍 - ' + name
        }
    },
    {
        //工作机会
        path: '/Recruit',
        name: 'Recruit',
        component: Recruit,
        meta: {
            title: '工作机会 - ' + name
        }
    },
    {
        //联系我们
        path: '/Contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: '联系我们 - ' + name
        }
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
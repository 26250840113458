<template>
  <div class="HomePage">
    <!--轮播图-->
    <div id="swiper" class="container-fuild">
      <div class="swiper-container banner-swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in photolist"
            :key="index"
          >
            <img class="swiper-lazy" :data-src="item.path" alt="轮播图" />
            <div class="swiper-slide-title">
              <h1>{{ item.title }}</h1>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <!--- 所有的软件产品-->
    <div id="bigData" class="container-fuild">
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-4 wow zoomIn col-md-offset-2">
          <img
            class="img-responsive"
            src="@/assets/img/soft.png"
            alt="软件产品"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4">
          <br />
          <br />
          <h2 class="bigData-title">软件产品</h2>
          <p>
            实现设施园艺、数字渔业、智慧灌溉、农产品质量安全追溯、农业病虫害监测的规范化及可视化、
            精准化操作和自动化生产，实用的产品功能，易懂的操作界面，稳定的产品性能，极致的用户体验。
          </p>
          <a href="#" class="btn btn-lg btn-block btn-info" @click="tosoft()"
            >查看详情</a
          >
        </div>
      </div>
    </div>
    <!--- 所有的硬件产品-->
    <div id="Hard" class="container-fuild">
      <div class="row Hard-container">
        <div class="col-xs-12 col-sm-12 col-md-4 wow zoomIn col-md-offset-2">
          <img
            class="img-responsive"
            src="@/assets/img/hard.png"
            alt="硬件产品"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4">
          <br />
          <br />
          <h2 class="Hard-title">硬件产品</h2>
          <p>
            为智慧农业深度定制化的产品，适合不同的场景，产品稳定可靠。获得多项专利
          </p>
          <a href="#" class="btn btn-lg btn-block btn-info" @click="tohard()"
            >查看详情</a
          >
        </div>
      </div>
    </div>


    <!--为什么选择我们-->
    <div id="whyChooseUs" class="conatiner-fuild">
      <div class="container">
        <div class="whyChooseUs-title text-center">
          <p>为什么选择我们的服务</p>
          <p>THE REASON TO CHOOSING US</p>
        </div>
        <div class="row">
          <div
            class="col-xs-12 col-sm-6 col-md-3 server-wrapper"
            v-for="item in serverList"
            :key="item.title1"
          >
            <div
              class="server-block wow slideInUp"
              onmouseenter="this.style.color='#28f';this.style.borderColor='#28f'"
              onmouseleave="this.style.color='#666';this.style.borderColor='#ccc'"
            >
              <img class="center-block" :src="item.logo" alt="logo" />
              <p class="text-center">{{ item.title1 }}</p>
              <div
                class="text-center"
                v-html="item.content"
                onmouseenter="this.style.color='#28f'"
                onmouseleave="this.style.color='#ccc'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import Swiper from "swiper";
export default {
  name: "home",
  data() {
    return {
      photolist: [
        {
          path: require("@/assets/img/水产.jpg"),
          title: "水产养殖",
        },
        {
          path: require("../assets/img/灌溉.jpg"),
          title: "智能灌溉",
        },
        {
          path: require("../assets/img/水肥.jpg"),
          title: "智能水肥",
        },
        {
          path: require("../assets/img/温室.jpg"),
          title: "智慧温室",
        },
      ],
      customerList: [
        {
          content: "客户评价",
          title: "评价内容1",
        },
        {
          content: "客户评价",
          title:
            "具有较强的工作能力和团队合作精神。有强烈的责任心和工作热情，并有良好的沟通能力。售后及时解决问题，态度也很好。",
        },
        {
          content: "客户评价",
          title: "评价内容3",
        },
        {
          content: "客户评价",
          title: "评价内容4",
        },
      ],
      serverList: [
        {
          logo: require("@/assets/img/优势.png"),
          title1: "行业经验",
          content:
            "在农业物联网领域，有着近十年的耕耘,积累了丰富的行业经验。与宁夏园艺推广站、中农万联等多个企业保持密切的合作关系",
        },
        {
          logo: require("@/assets/img/团队.png"),
          title1: "团队优势",
          content:
            "科技创新是公司永续发展的不竭动力，公司有着一支强大的技术科研团队，并且与中国农业大学等国内著名高校保持密切的合作关系。",
        },
        {
          logo: require("@/assets/img/定制化.png"),
          title1: "深度的定制化服务",
          content:
            "根据客户需求，一对一地为其提供解决方案，提供适合其需求的、适合其场景的、同时也是客户满意的定制化服务。",
        },
        {
          logo: require("@/assets/img/售后.png"),
          title1: "售后响应",
          content:
            "7x24小时提供专业的产品支持、产品保障、故障排除服务，使客户没有后顾之忧。",
        },
      ],
    };
  },
  mounted() {
    new Swiper(".banner-swiper", {
      loop: true,
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      speed: 1000,
      autoplayDisableOnInteraction: false,
      //自动播放
      autoplay: {
        delay: 5000,
        stopOnLastSlide: true,
        disableOnInteraction: true,
      },
      lazy: {
        loadPrevNext: true,
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
    });
    new Swiper(".customer-swiper", {
      loop: true, // 循环模式选项
      slidesPerView: 3,
      autoplayDisableOnInteraction: false,
      //自动播放
      speed: 1000,
      autoplay: {
        delay: 5000,
        stopOnLastSlide: true,
        disableOnInteraction: true,
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
    });
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
  methods: {
    tosoft() {
      this.$router.push("/Soft");
    },
    tohard() {
      this.$router.push("/Hard");
    },
  },
};
</script>

<style scoped>
#HomePage {
  width: 90%;
}

/* 轮播图 */
#swiper {
  height: 500px;
}
#swiper .banner-swiper {
  width: 100%;
  height: 100%;
}
#swiper .banner-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
}
#swiper .banner-swiper .swiper-slide {
  position: relative;
}
#swiper .banner-swiper .swiper-slide-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(51, 51, 51, 0.534);
  text-align: center;
  line-height: 80px;
}
#swiper .banner-swiper .swiper-slide-title > h1 {
  font-size: 50px;
  margin-top: 12%;
}
#swiper .banner-swiper .swiper-slide-title > p {
  font-size: 20px;
  margin-top: 1%;
  font-weight: 700;
}

#bigData {
  padding: 100px;
  transition: all ease 0.6s;
  box-sizing: border-box;
}
#bigData .bigData-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
#bigData img {
  width: 500px;
  transition: all ease 0.6s;
  box-sizing: border-box;
}
#bigData p {
  font-size: 14px;
  color: #333;
  line-height: 2rem;
  margin-bottom: 10px;
  margin-top: 10px;
}
#bigData .bigData-device {
  margin: 50px 0 20px;
}
/*硬件产品*/
#Hard {
  padding: 100px;
  transition: all ease 0.6s;
  box-sizing: border-box;
  background-color: #474747;
}
#Hard .Hard-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  color: #ccc;
}
#Hard img {
  width: 85%;
  transition: all ease 0.6s;
  box-sizing: border-box;
  height: 80%;
}
#Hard p {
  margin-top: 10px;
  font-size: 14px;
  color: #ccc;
  line-height: 2rem;
  margin-bottom: 10px;
}
#Hard .Hard-device {
  margin: 50px 0 20px;
}

#contactUs {
  color: #fff;
  height: 400px;

  background-size: 100% 100%;
  transition: all ease 0.6s;
}
#contactUs .contactUs-container {
  padding-top: 50px;
}
#contactUs .contactUs-container button {
  width: 300px;
  height: 50px;
  margin-top: 40px;
}
#contactUs .contactUs-container .contactUs-contactWay span {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 30px;
}

#customer {
  padding: 50px 0;
  box-sizing: border-box;
  background: #efefef;
  transition: all ease 0.6s;
}
#customer .customer-title {
  font-size: 30px;
  color: rgb(102, 102, 102);
  margin: 0 0 30px;
}
#customer .customer-block {
  background: #fff;
  padding: 30px;
}
#customer .customer-logo img {
  width: 94px;
  height: 94px;
  border: 1px solid #ccc;
}
#customer .customer-yh img {
  width: 34px;
  height: 34px;
}
#customer .customer-content1 {
  padding-bottom: 20px;
  border-bottom: 1px solid #0ce9f1;
}
#customer .customer-content2 {
  padding-top: 20px;
}
/* 为什么选择我们 */
#whyChooseUs {
  padding: 100px;
}
#whyChooseUs .whyChooseUs-title {
  margin-bottom: 50px;
}
#whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
  font-size: 25px;
  font-weight: 500;
}
#whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
  font-size: 14px;
}
#whyChooseUs .server-block {
  padding: 50px 20px;
  border: 1px solid #ccc;
  border-bottom: 5px solid #ccc;
}
#whyChooseUs .server-block img {
  width: 48px;
  height: 48px;
}
#whyChooseUs .server-block > p {
  font-size: 20px;
  margin: 30px 0;
}
#whyChooseUs .server-block > div {
  color: #ccc;
}
/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  #swiper {
    height: 200px;
  }
  #bigData {
    padding: 30px;
  }
  #bigData .bigData-title {
    font-size: 20px;
  }
  #bigData .bigData-device {
    font-size: 20px;
    margin: 10px 0 10px;
  }
  #Hard {
    padding: 30px;
  }
  #Hard .bigData-title {
    font-size: 20px;
  }
  #Hard .bigData-device {
    font-size: 20px;
    margin: 10px 0 10px;
  }
  #contactUs {
    height: 200px;
    transition: all ease 0.6s;
  }
  #contactUs .contactUs-container {
    padding-top: 0;
  }
  #contactUs .contactUs-container h1 {
    font-size: 25px;
  }
  #contactUs .contactUs-container h3 {
    font-size: 18px;
  }
  #contactUs .contactUs-container button {
    width: 200px;
    height: 30px;
    margin-top: 20px;
  }
  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 10px;
  }
  #customer {
    padding: 30px 0;
    box-sizing: border-box;
    background: #fff;
  }
  #customer .customer-title {
    font-size: 16px;
    font-weight: bold;
  }
  #customer .customer-logo img {
    width: 48px;
    height: 48px;
  }
  #customer .customer-block {
    padding: 30px;
  }
  #customer .customer-block > div {
    padding: 30px 0;
  }
  #whyChooseUs {
    padding: 20px 0;
    transition: all ease 0.6s;
  }
  #whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
    font-size: 20px;
    font-weight: 700;
  }
  #whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
    font-size: 12px;
  }
  #whyChooseUs .server-block {
    padding: 50px 0;
    border: 1px solid #ccc;
    border-bottom: 5px solid #ccc;
  }
  #whyChooseUs .server-block img {
    width: 48px;
    height: 48px;
  }
  #whyChooseUs .server-block > p {
    font-size: 20px;
    margin: 30px 0;
  }
  #whyChooseUs .server-block > div {
    color: #ccc;
  }
}

/* 媒体查询（平板） */
@media screen and (min-width: 768px) and (max-width: 996px) {
  #swiper {
    height: 400px;
  }
  #bigData {
    padding: 60px;
  }
  #bigData .bigData-title {
    font-size: 30px;
  }
  #bigData .bigData-device {
    font-size: 30px;
    margin: 30px 0 15px;
  }
  #Hard {
    padding: 60px;
  }
  #Hard .bigData-title {
    font-size: 30px;
  }
  #Hard .bigData-device {
    font-size: 30px;
    margin: 30px 0 15px;
  }
  #contactUs {
    height: 300px;
  }
  #contactUs .contactUs-container {
    padding-top: 50px;
  }
  #contactUs .contactUs-container h1 {
    font-size: 30px;
  }
  #contactUs .contactUs-container h3 {
    font-size: 20px;
  }
  #contactUs .contactUs-container button {
    width: 300px;
    height: 50px;
    margin-top: 30px;
  }
  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 15px;
  }
  #customer .customer-title {
    font-size: 24px;
  }
  #whyChooseUs {
    padding: 20px 0;
  }
}
</style>
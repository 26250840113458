<template>
  <div id="case_1">
    <div>
      <h2>{{ name }}</h2>
    </div >
       <br/>
    <div v-for="(intm, index) in imgpath" :key="index">
      <img :src="intm.path" alt="案例" > 
      <br>    <br> 
    </div>
  </div>
</template>
<script>
export default {
  name: "case2",
  data() {
    return {
      name: "贵州湄潭茶叶种植基地",
      imgpath: [
            { path: require('@/assets/case2/photo1.png'), },
            { path: require("@/assets/case2/photo2.png"), },
            { path: require("@/assets/case2/photo3.png"), },
      ],
    };
  },
};
</script>
<style scoped>
#case_1 {
  text-align: center;
}
#case_1 img {
  width: 90%;
    margin-top: 10px;
}
</style>


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import 'jquery'

// import BootstrapVue from 'bootstrap-vue'
// Vue.use(BootstrapVue)

// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap-vue/dist/bootstrap-vue.js'
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'


import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

import 'animate.css'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: "ePaA33m4qG6i5ltv0jEfR5gPNE35OiuF"
})

import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
Vue.use(ViewUI)

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
    //图片懒加载 配置项
Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 2
})

import Header from './components/header.vue'
Vue.component(Header.name, Header)
import Footer from './components/Footer.vue'
Vue.component(Footer.name, Footer)
import GoTop from './components/GoTop.vue'
Vue.component(GoTop.name, GoTop)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next();
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
<template>
  <div id="Solution4">
    <!--解决方案-->
    <div class="container">
      <div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div>
              <a @click="back()">&lt;-返回</a>
            </div>
            <div class="text">
              <h1>{{ name }}</h1>
              <p>{{ text1 }}</p>
              <img :src="img1" />
              <br/>
              <p>{{ text2 }}</p>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "农产品质量安全追溯系统",
      text1:
        "\t" +
        "习大大说：食品安全是“管”出来的。必须完善监管制度，强化监管手段，形成覆盖从田间到餐桌全过程的监管制度。农产品质量安全追溯系统，采用先进的农业物联网、防伪鉴真等技术，为农产品建立“身份证”制度，实现对产地环境、农业投入品、农事生产过程、质量检测、加工储运等质量安全关键环节全程可追溯，帮助企业打造高端农产品品牌，帮助政府实现全程安全监管。"+
       "\n\t" +
        "建立健全的安全品质监管、追溯、召回制度。",
      text2:
        "\t" +
        "溯源：追溯体系建设是采集记录产品生产、流通、消费等环节信息，实现来源可查、去向可追、责任可究，强化全过程质量安全管理与风险控制的有效措施。" +
        "\n\t" +
        "利国：保护民族品牌，促进社会和谐发展" +
        "\n\t" +
        "利民：维护人民权益，减少食品危害事件。"+
        "\n\t" +
        "利企：建立企业良好竞争环境。",
      img1: require("@/assets/Solution/Solution4/img1.png"),
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style >
#Solution4 h1 {
  white-space: pre;
  text-align: center;
  margin-bottom: 20px;
}
#Solution4 a {
  font-size: 20px;
}
#Solution4 p {
  white-space: pre-wrap;
}
#Solution4 h2 {
  white-space: pre-wrap;
  color: rgb(33, 160, 132);
}
#Solution4 .text {
  white-space: pre;
  font-size: 20px;
}
#Solution4 img {
  width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-bottom: 20px;
}
</style>
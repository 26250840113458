<template>
  <div class="container">
    <div class="row">
      <div id="left" class="col-md-4 col-xs-12 col-lg-3">
        <div v-for="(harditem, index) in hardList" :key="index">
          <div>
            <ul class="left-container wow bounceInLeft">
              <p @click="cutTab(harditem.id)">{{ harditem.name }}</p>
              <div class="left-container wow bounceInLeft" :id="harditem.name">
                <li
                  v-for="(item, index) in hard[harditem.id]"
                  :key="index"
                  :class="item.id == id ? 'active' : ''"
                  @click="switchTab(item, index)"
                >
                  {{ item.name }}
                </li>
              </div>
            </ul>
          </div>
          <br />
        </div>
      </div>
      <div
        id="right"
        class="col-md-8 col-xs-12 col-lg-8 col-md-offset-1 wow bounceInRight"
      >
        <img :src="hard[hardListnum][hardnum].path" alt="产品" />
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  name: "case",
  data() {
    return {
      id: "001",
      hardListnum: 0,
      hardnum: 0,
      hardList: [
        {
          name: "环境气象系列",
          id: 0,
        },
        {
          name: "土壤墒情系列",
          id: 1,
        },
        {
          name: "水体检测系列",
          id: 2,
        },
        {
          name: "植物类传感器",
          id: 3,
        },
        {
          name: "低功耗数据采集器",
          id: 4,
        },
        {
          name: "多路多参数数据采集器",
          id: 5,
        },
        {
          name: "控制器",
          id: 6,
        },
        {
          name: "低功耗阀门控制器",
          id: 7,
        },
        {
          name: "气象站",
          id: 8,
        },
        {
          name: "控制柜",
          id: 9,
        },
        {
          name: "水肥机",
          id: 10,
        },
      ],
      hard: [
        [
          {
            id: "001",
            path: require("../assets/Product/hard/风速.jpg"),
            name: "风速传感器",
          },
          {
            id: "002",
            path: require("../assets/Product/hard/风向.jpg"),
            name: "风向传感器",
          },
          {
            id: "003",
            path: require("../assets/Product/hard/雨量.jpg"),
            name: "雨量传感器",
          },
          {
            id: "004",
            path: require("../assets/Product/hard/光照度.jpg"),
            name: "光照度传感器",
          },
          {
            id: "005",
            path: require("../assets/Product/hard/蒸发.jpg"),
            name: "蒸发传感器",
          },
          {
            id: "006",
            path: require("../assets/Product/hard/光合有效.jpg"),
            name: "光合有效传感器",
          },
          {
            id: "007",
            path: require("../assets/Product/hard/空气温湿度.jpg"),
            name: "空气温湿度传感器",
          },
          {
            id: "008",
            path: require("../assets/Product/hard/二氧化碳.jpg"),
            name: "二氧化碳传感器",
          },
          {
            id: "009",
            path: require("../assets/Product/hard/大气压力.jpg"),
            name: "大气压力传感器",
          },
          {
            id: "010",
            path: require("../assets/Product/hard/空气颗粒物浓度.jpg"),
            name: "空气颗粒物浓度传感器",
          },
          {
            id: "011",
            path: require("../assets/Product/hard/空气温湿度_光照.jpg"),
            name: "空气温湿度、光照" + "\n" + "三参数传感器",
          },
          {
            id: "012",
            path: require("../assets/Product/hard/空气温湿度_大气压力.jpg"),
            name: "空气温湿度、大气压力" + "\n" + "三参数传感器",
          },
          {
            id: "013",
            path: require("../assets/Product/hard/空气温湿度_露点温度.jpg"),
            name: "空气温湿度、露点温度" + "\n" + "三参数传感器",
          },
          {
            id: "014",
            path: require("../assets/Product/hard/空气温湿度_二氧化碳_光照.jpg"),
            name: "空气温湿度、二氧化碳、光照" + "\n" + "四参数传感器",
          },
          {
            id: "015",
            path: require("../assets/Product/hard/空气温湿度_光照_大气压力.jpg"),
            name: "空气温湿度、光照度、大气压力" + "\n" + "四参数传感器",
          },
          {
            id: "016",
            path: require("../assets/Product/hard/空气温湿度_大气压力_二氧化碳_光照度.jpg"),
            name:
              "空气温湿度、大气压力、二氧化碳、光照" + "\n" + "五参数传感器",
          },
        ],
        [
          {
            id: "017",
            path: require("../assets/Product/hard/管式墒情.jpg"),
            name: "管式墒情测试仪",
          },
          {
            id: "018",
            path: require("../assets/Product/hard/土壤温湿度_电导率_盐分.jpg"),
            name: "土壤温湿度电导率盐分传感器",
          },
          {
            id: "019",
            path: require("../assets/Product/hard/土壤氮磷钾.jpg"),
            name: "土壤氮磷钾传感器",
          },
          {
            id: "020",
            path: require("../assets/Product/hard/土壤温湿度.jpg"),
            name: "土壤温湿度传感器",
          },
          {
            id: "021",
            path: require("../assets/Product/hard/土壤水分.jpg"),
            name: "土壤水分传感器",
          },
          {
            id: "022",
            path: require("../assets/Product/hard/土壤温度.jpg"),
            name: "土壤温度传感器",
          },
          {
            id: "023",
            path: require("../assets/Product/hard/土壤电导率.jpg"),
            name: "土壤电导率传感器",
          },
        ],
        [
          {
            id: "024",
            path: require("../assets/Product/hard/溶解氧.jpg"),
            name: "溶解氧传感器",
          },
          {
            id: "025",
            path: require("../assets/Product/hard/PH值.jpg"),
            name: "PH值传感器",
          },
          {
            id: "026",
            path: require("../assets/Product/hard/电导率.jpg"),
            name: "电导率传感器",
          },
          {
            id: "027",
            path: require("../assets/Product/hard/氨氮.jpg"),
            name: "氨氮传感器",
          },
          {
            id: "028",
            path: require("../assets/Product/hard/叶绿素.jpg"),
            name: "叶绿素传感器",
          },
          {
            id: "029",
            path: require("../assets/Product/hard/浊度.jpg"),
            name: "浊度传感器",
          },
          {
            id: "030",
            path: require("../assets/Product/hard/液位.jpg"),
            name: "液位传感器",
          },
        ],
        [
          {
            id: "031",
            path: require("../assets/Product/hard/果实生长.jpg"),
            name: "果实生长传感器",
          },
          {
            id: "032",
            path: require("../assets/Product/hard/茎秆生长.jpg"),
            name: "茎秆生长传感器",
          },
          {
            id: "033",
            path: require("../assets/Product/hard/叶面温湿度.jpg"),
            name: "叶面温湿度传感器",
          },
        ],
        [
          {
            id: "034",
            path: require("../assets/Product/hard/低功耗数据采集.jpg"),
            name: "低功耗数据采集终端",
          },
        ],
        [
          {
            id: "035",
            path: require("../assets/Product/hard/多参数数据采集.jpg"),
            name: "多路多参数数据采集终端",
          },
        ],
        [
          {
            id: "036",
            path: require("../assets/Product/hard/控制器.jpg"),
            name: "智能控制终端",
          },
        ],
        [
          {
            id: "037",
            path: require("../assets/Product/hard/阀控器.jpg"),
            name: "阀门控制器",
          },
        ],
        [
          {
            id: "038",
            path: require("../assets/Product/hard/气象站.jpg"),
            name: "气象站",
          },
        ],
        [
          {
            id: "039",
            path: require("../assets/Product/hard/控制柜.jpg"),
            name: "控制柜",
          },
        ],
        [
          {
            id: "040",
            path: require("../assets/Product/hard/水肥机.jpg"),
            name: "水肥机",
          },
        ],
      ],
    };
  },
  mounted() {
    var wow = new WOW();
    wow.init();
    for (var i = 0; i < 15; i++) {
      document.getElementById(this.hardList[i].name).style.display = "none";
    }
    // document.getElementById(this.hardList[0].name).style.display = "inherit";
  },
  methods: {
    /** 切换tab */
    switchTab(item, index) {
      this.id = item.id;
      this.hardnum = index;
      window.scrollTo({
        left: 0,
        top: 200,
        behavior: "smooth",
      });
    },
    cutTab(hardNum) {
      for (var i = 0; i < 15; i++) {
        if (i == hardNum) {
          document.getElementById(this.hardList[hardNum].name).style.display = "inherit";
        } else {
          document.getElementById(this.hardList[i].name).style.display = "none";
        }
        window.scrollTo({
          left: 0,
          top: 200,
          behavior: "smooth",
        });
        this.hardListnum = hardNum;
        this.hardnum = 0;
      }
    },
  },
};
</script>
<style scoped>
#left {
  margin: 50px 0;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
}
.container {
  min-height: 500px;
}
.left-container {
  margin: 0 auto;
  border: 1px solid #474747;
  border-radius: 5px;
  padding: 0;
}
.left-container > p {
  text-align: center;
  line-height: 45px;
  padding: 0;
  margin: 0;
  background: #474747;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.left-container > li {
  text-align: center;
  height: auto;
  line-height: 38px;
  margin: 0;
  border-top: 1px solid #474747;
  cursor: pointer;
  list-style: none;
  padding: 0;
}
.left-container > li > a {
  text-decoration: none;
}
.left-container > li.active,
.left-container > li.active:hover {
  background: #928989;
}
#right {
  padding: 50px 0;
}
#right img{
  width: 100%;
}
@media screen and (max-width: 768px) {
  #right {
    padding: 15px;
    width: 100%;
  }
    #right img{
    padding: 15px;
    width: 100%;
  }
}
</style>


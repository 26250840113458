<template>
  <div id="solutionHome">
    <!--解决方案-->
    <div class="container">
      <div v-for="(item, index) in solutions" :key="index" class="item">
        <div class="row CompanyIntroduction-container">
          <div id="photo" class="col-xs-12 col-sm-12 col-md-5">
            <img :src="item.imgpath" alt="解决方案" class="caseimg" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-7">
            <div class="title">
               <router-link :to="item.path">{{ item.title }} </router-link> 
            </div>
            <div class="text">
              <p>{{ item.introduce }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "solutionHome",
  data() {
    return {
      solutions: [
        {
          imgpath: require("@/assets/img/ssyy.jpg"),
          title: "设施园艺解决方案",
          introduce:
            "系统利用物联网技术，可实时远程获取温室大棚内部的空气温湿度、土壤温湿度、CO2浓度、光照强度及视频图像，通过模型分析，自动控制湿帘风机、喷淋滴灌、内外遮阳、顶窗侧窗、加温补光等设备，保证温室大棚环境最适宜作物生长，为农作物优质、高产、高效、安全创造条件",
          path: "/Solution/Solution1",
        },
        {
          imgpath: require("@/assets/img/scyz.jpg"),
          title: "数字渔业解决方案",
          introduce:
            "通过对水产品养殖水质和环境信息的实时在线监测、预警、控制，实现精细投喂，疾病预警与远程诊断，生产与物流管理，质量追溯等功能，改变传统水产养殖业存在的缺乏有效监控手段、水产养殖饵料与药品投喂不合理、水产养殖疾病频发等问题，促进水产养殖业生产方式转变，提高生产效率，保障食品安全。",
          path: "/Solution/Solution2",
        },
        {
          imgpath: require("@/assets/img/zngg.jpg"),
          title: "智慧灌溉（水肥一体）系统",
          introduce:
            "针对种植现场分布广、监测点多、布线和供电困难等特点，融合物联网和云计算技术，采用高精度温湿度传感器和智能气象站，远程在线采集气象信息，实现灌溉用水量智能决策、手机APP远程/自动控制灌溉等功能，通过精确控制灌水量，实现水肥利用率显著提高，实现节水、节肥、省工、优质、高效、增产和增收的效果。",
          path: "/Solution/Solution3",
        },
        {
          imgpath: require("@/assets/img/zlaqzs.png"),
          title: "农产品质量安全追溯系统",
          introduce:
            "农产品质量安全追溯系统，采用先进的农业物联网、防伪鉴真等技术，为农产品建立“身份证”制度，实现对产地环境、农业投入品、农事生产过程、质量检测、加工储运等质量安全关键环节全程可追溯，帮助企业打造高端农产品品牌，帮助政府实现全程安全监管。",
          path: "/Solution/Solution4",
        },
        {
          imgpath: require("@/assets/img/bchjc.png"),
          title: "农作物病虫害监测系统",
          introduce:
            "农作物病虫害监测系统，采用智能虫情测报系统、智能病害监测系统，实时预警监控系统、自动性诱检测仪、农业环境监控系统.",
          path: "/Solution/Solution5",
        },
      ],
    };
  },
  methods: {
    solution(index) {
      this.$router.push(this.solutions[index].path)
    },
  },
};
</script>

<style>
#solutionHome .title {
  font-size: 25px;
  font-family: "Microsoft YaHei";
  margin-top: 30px;
}
#solutionHome .text {
  font-size: 15px;
  font-family: "Microsoft YaHei";
  margin-top: 30px;
}
#solutionHome .item {
  /*border: 2px solid green;*/
  width: 1000px;
  display: flex;
  margin: auto;
  margin-top: 40px;
  background-color: #f4f4f4;
}
#solutionHome .item .caseimg {
  width: 350px;
}
@media screen and (max-width: 768px) {

  #solutionHome .item {
    /*border: 2px solid green;*/
    width: 100%;
    display: flex;
    margin: auto;
    margin-top: 40px;
  }
  #solutionHome .item .caseimg {
    width: 100%;
  }
}
</style>
<template>
  <div id="case_1">
    <div>
      <h2>{{ name }}</h2>
    </div>
    <br />
    <div v-for="(intm, index) in imgpath" :key="index">
      <img :src="intm.path" alt="案例" />
      <br />
      <br />
    </div>
  </div>
</template>
<script>
export default {
  name: "case1",
  data() {
    return {
      name: "中国农大通州实验基地",
      imgpath: [
        { path: require("@/assets/case1/photo1.jpg") },
        { path: require("@/assets/case1/photo2.jpg") },
      ],
    };
  },
};
</script>
<style scoped>
#case_1 {
  text-align: center;
}
#case_1 img {
  width: 100%;
  margin-top: 10px;
}
</style>


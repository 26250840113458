<template>
  <div class="container">
    <div class="row">
      <div id="left" class="col-md-4 col-xs-12 col-lg-3">
        <ul class="left-container wow bounceInLeft">
          <p>软件产品</p>
          <div class="left-container wow bounceInLeft">
            <li
              v-for="(item, index) in soft"
              :key="index"
              :class="item.id == id ? 'active' : ''"
              @click="switchTab(item, index)"
            >
              {{ item.name }}
            </li>
          </div>
        </ul>
      </div>
      <div
        id="right"
        class="col-md-8 col-xs-12 col-lg-8 col-md-offset-1 wow bounceInRight"
      >
        <img :src="soft[hardnum].path" alt="软件产品" />
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  name: "case",
  data() {
    return {
      id: "001",
      hardnum: 0,
      soft: [
        {
          id: "001",
          path: require("../assets/Product/soft/设施园艺.jpg"),
          name: "设施园艺",
        },
        {
          id: "002",
          path: require("../assets/Product/soft/水产养殖.jpg"),
          name: "水产养殖",
        },
        {
          id: "003",
          path: require("../assets/Product/soft/大田灌溉.jpg"),
          name: "大田灌溉",
        },
        {
          id: "004",
          path: require("../assets/Product/soft/小程序.jpg"),
          name: "小程序",
        },
      ],
    };
  },
  mounted() {
    var wow = new WOW();
    wow.init();
    // for (var i = 0; i < 15; i++) {
    //   document.getElementById(this.hardList[i].name).style.display = "none";
    // }
    // // document.getElementById(this.hardList[0].name).style.display = "inherit";
  },
  methods: {
    /** 切换tab */
    switchTab(item, index) {
      this.id = item.id;
      this.hardnum = index;
      window.scrollTo({
        left: 0,
        top: 200,
        behavior: "smooth",
      });
    },
    cutTab(hardNum) {
      for (var i = 0; i < 15; i++) {
        if (i == hardNum) {
          document.getElementById(this.hardList[hardNum].name).style.display =
            "inherit";
        } else {
          document.getElementById(this.hardList[i].name).style.display = "none";
        }
        window.scrollTo({
          left: 0,
          top: 200,
          behavior: "smooth",
        });
        this.hardListnum = hardNum;
        this.hardnum = 0;
      }
    },
  },
};
</script>
<style scoped>
#left {
  margin: 50px 0;
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
}
.container {
  min-height: 500px;
}
.left-container {
  margin: 0px;
  border: 1px solid #474747;
  border-radius: 5px;
  padding: 0px;
}
.left-container > p {
  text-align: center;
  line-height: 45px;
  padding: 0;
  margin: 0;
  background: #474747;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.left-container > li {
  text-align: center;
  height: auto;
  line-height: 38px;
  margin: 0;
  border-top: 1px solid #474747;
  cursor: pointer;
  list-style: none;
}
.left-container > li > a {
  text-decoration: none;
}
.left-container > li.active,
.left-container > li.active:hover {
  background: #928989;
}
#right {
  padding: 50px 0;
}
#right img{
  width: 100%;
}
@media screen and (max-width: 768px) {
  #right {
    padding: 15px;
  }
  #right img{
    padding: 15px;
    width: 100%;
  }
}
</style>


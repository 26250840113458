<template>
  <div id="case_1">
    <div>
      <h3>{{ name }}</h3>
    </div >
    <br/>
    <div v-for="(intm, index) in imgpath" :key="index">
      <img :src="intm.path" alt="案例" >   
      <h1></h1>
    </div>
  </div>
</template>
<script>
export default {
  name: "case8",
  data() {
    return {
      name: "智能水肥一体机",
      imgpath: [
            { path: require('@/assets/case9/水肥机1.jpg'), },
            { path: require("@/assets/case9/水肥机2.png"), },
      ],
    };
  },
};
</script>
<style scoped>
#case_1 {
  text-align: center;
}
#case_1 img {
  margin-top: 10px;
  width: 100%;
}
</style>


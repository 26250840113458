<template>
  <div id="Solution3">
    <!--解决方案-->
    <div class="container">
      <div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div>
              <a @click="back()">&lt;-返回</a>
            </div>
            <div class="text">
              <h1>{{ name }}</h1>
              <p>{{ text1 }}</p>
              <img :src="img1" />
              <h2>系统功能</h2>
              <p>{{ text2 }}</p>
              <img :src="img2" />
              <br/>
              <img :src="img3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "智慧灌溉（水肥一体）系统",
      text1:
        "\t" +
        "传统的灌溉施肥采用人工灌溉，经验判断方式，导致水资源浪费严重，且由于全部依赖人工，工作量大，效率低，成本高。"+
       "\n\t" +
        "智慧灌溉系统根据土壤墒情数据、植物生理生态数据等采集到的环境气候信息，给电磁阀发指令，电磁阀接收到命令后制定相关灌溉计划及施肥。根据农作物的生长周期，调节相对应的灌溉计划，使作物生长达到最佳状态，提高产品质量。",
      text2:
        "\t" +
        "1、远程操控阀门启闭：系统可远程进行控制，减少人工成本，提高成产效率。" +
        "\n\t" +
        "2、移动端（手机）/PC端查看：可以使用手机小程序进行实时数据查看，也可在电脑端进行数据查看。" +
        "\n\t" +
        "3、可实时根据传感器的获取到的值自动的执行开始或结束灌溉。"+
        "\n\t" +
        "4、可定时、定量的执行灌溉或施肥。",
      img1: require("@/assets/Solution/Solution3/img1.png"),
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style >
#Solution3 h1 {
  white-space: pre;
  text-align: center;
  margin-bottom: 20px;
}
#Solution3 a {
  font-size: 20px;
}
#Solution3 p {
  white-space: pre-wrap;
}
#Solution3 h2 {
  white-space: pre-wrap;
  color: rgb(33, 160, 132);
}
#Solution3 .text {
  white-space: pre;
  font-size: 20px;
}
#Solution3 img {
  width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-bottom: 20px;
}
</style>
<template>
  <div id="JobChance">
    <div class="banner container-fuild text-center">工作机会</div>
    <div class="container">
      <div class="JobChance-container wow pulse" v-for="(item,index) in jobChanceList" :key="index">
        <h2>{{item.title}}</h2>
        <p>岗位职责</p>
        <div class="zy-content" v-html="item.jobResponsibilities"></div>
        <p>任职要求</p>
        <div class="zy-content" v-html="item.jobRequirements"></div>
        <button class="center-block btn btn-warning btn-lg" @click="showModal(item)">投递简历</button>
      </div>
    </div>

    <!-- 投递简历的弹窗 -->
    <Modal v-model="modal" title="投递简历">
        <p>感谢您的投递，请将简历发送至：
          <Tooltip content="点击即可发送简历">
            <a id="sendEmailA" :href="sendEmail" rel="nofollow">{{email}}</a>
          </Tooltip>
          邮箱，我们看到后会与您联系！
        </p>
        <div slot="footer">
          <Button type="primary" @click="modal = false">关闭</Button>
          <!--Button type="primary" @click="sendEmailBtn">发送</Button-->
        </div>
    </Modal>
  </div>
</template>
<script>
import { WOW } from 'wowjs';
import info from "../../CorpInfo/info.js";
export default {
  name: "JobChance",
  data() {
    return {
      jobChanceList: [
        {
          title: "单片机工程师",
          jobResponsibilities: "1.负责公司单片机产品程序开发；<br /> 2.设计硬件电路，完成上级安排的研发任务；<br />3.编制产品开发技术文档、指导书等；<br />",
          jobRequirements: "1. 大学本科及以上，专业为电子、计算机、通信等电类相关专业优先；<br /> 2. 熟悉数模电知识，熟练单片机知识，有相关项目经验者优先；<br /> 3. 两年及以上单片机设计经验、能独立完成项目的设计，精通C/C++语言、能熟练使用Keil/IAR编程环境；<br /> 4. 熟练使用PCB电路设计软件（Altium）；<br /> 5. 熟悉基本硬件原理、SPI、I2C、UART、ZigBee等通讯协议；<br /> 6. 熟练使用万用表、示波器等基本调试工具；<br /> 7. 能熟练使用STM32者优先考虑；<br /> 8. 有出色的语言组织能力和文字功底，自学能力强，能积极主动解决问题；<br /> 9. 有创业奋斗精神，能自觉加班保质保量完成相关工作者可适当放宽条件，一经录用待遇从优（具体待遇面议，保证不低于同类公司相关待遇）。",
          email: info.email,
        },
        {
          title: "web前端开发工程师",
          jobResponsibilities: "1. 负责传统 web 页面，基于 vue 的页面开发；<br />2. 负责页面的各种浏览器(IE9+)兼容性支持，移动端支持；<br />3. 参与产品需求讨论，进行原型、交互设计；<br />4. 负责上线、维护前端服务；<br />5. 负责产品设计和前端交互<br />",
          jobRequirements: "1.熟练使用前端开发中的语⾔言，HTML，CSS，JS（ES6）；<br />2.有Vue的实际项目经验，熟练应用 vue 的周边资源；<br />3.掌握至少一种常用前端工程工具（Webpack/Gulp...）的使用；<br />4.熟悉git操作；<br />5.能使用node（或其他后端语言）编写脚本和简单的服务；<br />6.对敏捷开发方式有深入理解，并能搭建环境；<br />7.突出的学习能力和逻辑思维能力，优秀的沟通能力。",
          email: info.email,
        },
      ],
      modal: false,
      email: '',
      sendEmail: ''
    };
  },
  mounted(){
    var wow = new WOW();
    wow.init();
  },
  methods: {
    /** 显示模态窗 */
    showModal(item) {
      this.email = item.email
      this.sendEmail = "mailto:"+item.email+"?subject="+item.title+"面试简历&body=您好，附件中为本人简历，请查收，希望能有机会和贵公司进一步合作!"
      this.modal = true
    },
    /** 发送邮件 */
/*     sendEmailBtn() {
      document.getElementById("sendEmailA").click();
    } */
  }
};
</script>
<style scoped>
.banner {
  color: #fff;
  font-size: 30px;
  height: 150px;
  line-height: 150px;
  background-image: url("../assets/img/banner_2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
}
.JobChance-container {
  margin: 100px;
  padding: 30px;
  transition: all ease 0.5s;
  border: 1px dashed salmon;
}
.JobChance-container h2{
  color: rgb(255, 102, 19);
  font-weight: bolder;
  text-align: center;
}
.JobChance-container p{
  font-size: 20px;
  color: rgb(255, 102, 19);
  font-weight: 700;
}
.zy-content{
  padding-left: 20px;
  margin-bottom: 10px;
}
.JobChance-container ol{
  list-style-type: decimal;
  padding-left: 30px;
}
.JobChance-container ol>li{
  font-size: 14px;
  line-height: 2.7rem;
}
@media screen and (max-width: 997px) {
  .JobChance-container {
    margin: 20px 0;
    padding: 20px;
    border: 1px dashed salmon;
  }
}
.zy-email {
  color: blue;
  cursor: pointer;
}
</style>


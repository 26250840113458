<template>
  <div class="container">
    <div class="row">
      <div id="left" class="col-md-4 col-xs-12 col-lg-4" >
        <ul class="left-container wow bounceInLeft">
          <p>成功案例</p>
          <li
            v-for="(item, index) in CaseList"
            :key="index"
            :class="item.id == id ? 'active' : ''"
            @click="switchTab(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div id="right" class="col-md-8 col-xs-12 wow bounceInRight">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  name: "case",
  data() {
    return {
      id: "001",
      CaseList: [
        {
          id: "001",
          path: "/Case/case1",
          name: "中国农大通州实验基地",
        },
        {
          id: "002",
          path: "/Case/case2",
          name: "贵州湄潭茶叶种植基地",
        },
        {
          id: "003",
          path: "/Case/case3",
          name: "贵州桶井乡现代高效休闲农业园区",
        },
        {
          id: "004",
          path: "/Case/case4",
          name: "海南大学植物保护学院",
        },
        {
          id: "005",
          path: "/Case/case5",
          name: "甘肃省大漠田园",
        },
        {
          id: "006",
          path: "/Case/case6",
          name: "京东农场",
        },
        {
          id: "007",
          path: "/Case/case7",
          name: "华大基因国家海洋基因库水产养殖",
        },
        {
          id: "008",
          path: "/Case/case8",
          name: "青海积石峡水产增殖站项目",
        },
        {
          id: "009",
          path: "/Case/case9",
          name: "智能水肥一体机",
        },
      ],
    };
  },
  mounted() {
    var wow = new WOW();
    wow.init();
  },
  methods: {
    /** 切换tab */
    switchTab(item) {
      this.id = item.id;
      let route = {
        path: item.path,
        params: {},
      };
      this.$router.push(route.path);
    },
  },
};
</script>
<style scoped>
#left {
  margin: 10px 0;
  border-radius: 5px;
}
.container {
  min-height: 500px;

}
.left-container {
  width: 70%;
  margin: 0 auto;
  border: 1px solid #474747;
  padding: 0;
}
.left-container > p {
  text-align: center;
  line-height: 45px;
  padding: 0;
  margin: 0;
  background: #474747;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  position: sticky;
}
.left-container > li {
  text-align: center;
  width: 100%;
  height: 38px;
  line-height: 38px;
  margin: 0;
  padding: 0;
  border-top: 1px solid #474747;
  cursor: pointer;
  list-style: none;
  margin-left:0;
}
.left-container > li > a {
  text-decoration: none;
}
.left-container > li.active,
.left-container > li.active:hover {
  background: #928989;
}
#right {
  padding: 50px 0;
}
@media screen and (max-width: 768px) {
  #right {
    padding: 15px;
  }
      #right img{
    padding: 15px;
    width: 100%;
  }
}
</style>


<template>
  <div id="Solution1">
    <!--解决方案-->
    <div class="container">
      <div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
          <div>
            <a @click="back()">&lt;-返回</a>
          </div>
            <div class="text">
              <h1>{{ name }}</h1>
              <p>{{ text1 }}</p>
              <img :src="img2">
              <h2>系统功能</h2>
              <p>{{ text2 }}</p>
              <h2>系统优势</h2>
              <p>{{ text3 }}</p>             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "设施园艺",
      text1:
        "\t"+"设施园艺物联网系统可远程获取现场环境的土壤温湿度、土壤氮磷钾、土壤水分、土壤电导率、二氧化碳、光照度、空气温湿度、果实生长、叶面温湿度及视频图像。同时，还可以通过手机及电脑端发送实时数据，实现现场数据远程管理，有效减少了人工成本。",
      text2:
        "\t"+"1、生产环境实时监测，自动报警 " +
        "\n\t" +
        "2、24小时数据监控 " +
        "\n\t" +
        "3、手机/电脑端远程控制",
      text3:
        "\t"+"1、智能报警，及时发现 " +
        "\n\t" +
        "2、智能控制，减少人工成本  " +
        "\n\t" +
        "3、可接入多种传感器，个性化需求  " +
        "\n\t" +
        "4、手机、电脑多终端控制",
      img1: require("../../assets/Solution/Solution1/img1.png"),
      img2: require("@/assets/Solution/Solution1/img2.jpg"),
      img3: require("@/assets/Solution/Solution1/img3.png"),
      img4: require("@/assets/Solution/Solution1/img4.jpg"),
    };
  },
  methods: {
    back(){
      this.$router.go(-1)
    }
  }
};
</script>

<style >
#Solution1 h1 {
  white-space: pre;
  text-align: center;
  margin-bottom: 20px;
}
#Solution1 a{
  font-size: 20px;
} 
#Solution1 p {
  white-space: pre-wrap;
}
#Solution1 h2 {
  white-space: pre-wrap;
  color: rgb(33, 160, 132);
}
#Solution1 .text {
  white-space: pre;
  font-size: 20px;
}
#Solution1 img {
  width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-bottom: 20px;
}
</style>